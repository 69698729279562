import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormControl, Typography } from '@mui/material';
import { RootState } from '../../store';
import { setCategory } from '../../store/features/filterSlice';

const CategoryFilter = () => {
  const dispatch = useDispatch()
  const defaultCategory = useSelector((state: RootState) => state.filterReports.category)
  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => dispatch(setCategory(value))
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setCategory((event.target as HTMLInputElement).value))
  };

  const formControlLabelSx = { 
    py: 0,
    fontStyle: 'normal',
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'Open Sans',
    lineHeight: '18px'
  }
  const radioSx = { my: 0.5, py: 0.1 }

  const labels = ["All", "Combine", "Connectivity", 
    "Customer Analytics", "Customer Cooperator", 
    "Connection Quality", "Diagnostics", "Farming Operations", 
    "FOTA", "GSS Telematics", "Machine Learning", "Mobile Apps",
    "Positioning and Guidance", "Software Tracking",
    "Support Operations", "Sustainability", "Vehicle Analytics"
  ]

  return (
    <Box sx={{ pt: 1, ml: 1 }} >
      <Typography 
        fontWeight={600}
        fontStyle="normal"
        fontFamily="Open Sans"
        align="left"
        fontSize={14}
        pb={1}>
          Filter by Category
      </Typography>
        <FormControl sx={{ display: 'flex' }}>
          <RadioGroup
            defaultValue={defaultCategory}
            value={defaultCategory}
            name="radio-buttons-group"
            onChange={handleChange}
          >
            {labels.map(label => (
              <FormControlLabel value={label} sx={formControlLabelSx} control={<Radio sx={radioSx} />} label={label} />
            ))}
          </RadioGroup>
        </FormControl>
    </Box>
  )
}

export default CategoryFilter